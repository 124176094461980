import React, { useContext, useEffect, useState } from 'react';
import { useTelegram } from '../hooks/useTelegram';
import { updateUser, check } from '../http/userApi';
import { Context } from '../index';
import { observer } from "mobx-react-lite";
import "./Wallet.css";

const Wallet = observer(() => {
    const { user } = useContext(Context);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [walletValue, setWalletValue] = useState('');

    const fetchData = async () => {
        try {
            const data = await check();
            setUserInfo(data);
            setWalletValue(data.wallet || ''); // Устанавливаем значение кошелька из userInfo
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleUpdateWallet = async () => {
        try {
            await updateUser(
                userInfo.telegramID,
                userInfo.username,
                userInfo.role,
                userInfo.balance,
                userInfo.level,
                userInfo.attempts,
                walletValue
            );
            alert("Кошелек успешно обновлен!");
        } catch (error) {
            console.error("Ошибка при обновлении кошелька:", error);
            alert("Ошибка при обновлении кошелька.");
        }
    };

    if (loading) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className="wallet-container">
            <h1 className='pageTitle'>Your Wallet</h1>
            <input
                type="text"
                value={walletValue} // Используем walletValue для отображения
                onChange={(e) => setWalletValue(e.target.value)}
                placeholder="put your ton wallet adress"
                className="wallet-input"
            />
            <button onClick={handleUpdateWallet} className="wallet-button">Update Wallet Adress</button>
        </div>
    );
});

export default Wallet;
