import {$authHost, $host} from "./index"
import { jwtDecode } from "jwt-decode";

export const createTask = async (task) => {
    try{
    const {data} = await $authHost.post('api/task', task)
    return data;
} catch(e) {
    console.log(e)
}
}


export const fetchTasks = async () => {
    const {data} = await $host.get('api/task')
    return data;
}

export const fetchOneTasks = async (id) => {
    const {data} = await $host.get('api/task/' + id)
    return data;
}

export const markTaskAsCompleted = async (data) => {
    try {
        const response = await $authHost.post('api/task/complete', data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const checkTaskCompletion = async (userId, taskId) => {
    try {
        const { data } = await $host.get(`api/task/check/${userId}/${taskId}`);
        return data;
    } catch (e) {
        console.log(e);
    }
};

export const deleteTask = async (id) => {
    try {
        const response = await $authHost.delete(`api/task/${id}`);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const createQuizTask = async (task) => {
    try {
        const { data } = await $authHost.post('api/task/quiz', task);
        return data;
    } catch (e) {
        console.log(e);
    }
};






