import React, { useState } from "react";
import CreateTask from "../components/modals/CreateTask/CreateTask";
import './Pc.css'


const Pc = () => {
    const [toggle, setToggle] = useState(true);

    return (

        <div className="pc_notification">
            Зайди с телефона
        </div>
    );
};

export default Pc;