import { TASK_ROUTE } from "../../utils/consts";
import "./TaskItem.css";
import { useNavigate } from 'react-router-dom';
import { deleteTask } from "../../http/taskApi";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../index"; 
import { check } from "../../http/userApi";

const TaskItem = ({ task }) => {
    let navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});

    const fetchData = async () => {
        try {
            const data = await check();
            setUserInfo(data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function handleClick() {
        navigate(TASK_ROUTE + '/' + task.id);
    }

    const handleDelete = async (event) => {
        event.stopPropagation();
        const confirmed = window.confirm("Вы уверены, что хотите удалить задание?");
        if (confirmed) {
            try {
                await deleteTask(task.id);
                alert("Задание удалено, перезайдите на страницу");
            } catch (error) {
                console.log("Ошибка при удалении задания:", error);
            }
        }
    };

    // Условие для отображения изображения
    const shouldShowImage = !(task.isQuiz && task.image); // Отображать изображение, если это не квиз или если изображение отсутствует
    const imageUrl = shouldShowImage ? `${process.env.REACT_APP_API_URL}/images/${task.image}` : '';

    return (
        <div className="task_item_block" onClick={handleClick}>
            {shouldShowImage && <img className="task_item_image" src={imageUrl} alt="Изображение задания" />}
            {task.title}
            {userInfo.role === "ADMIN" && (
                <div className="delete_icon" onClick={handleDelete}> 🗑️ </div>
            )}
        </div>
    );
};

export default TaskItem;
