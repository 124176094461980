import {$authHost, $host} from "./index";
import { jwtDecode } from "jwt-decode";


// Вход пользователя
export const login = async (telegramID, username) => {
    const { data } = await $host.post('api/user/login', { telegramID, username });
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

export const registration = async (telegramID, username, inviterTelegramId) => {
    const { data } = await $host.post('api/user/registration', { telegramID, username, inviterTelegramId });
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

export const check = async () => {
    const { data } = await $authHost.get('api/user/auth');
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

// Обновление данных пользователя
export const updateUser = async (telegramID, username, role, balance, level, attempts, wallet) => {
    const { data } = await $authHost.put('api/user/update', { telegramID, username, role, balance, level, attempts, wallet });
    localStorage.setItem('token', data.token);
    return jwtDecode(data.token);
}

// Пополнение баланса пользователя
export const addBalance = async (telegramID, amount) => {
    const { data } = await $authHost.post('api/user/add-balance', { telegramID, amount });
    return data;
}

// Получение рефералов пользователя
export const fetchReferrals = async (telegramID) => {
    try {
        const { data } = await $authHost.get('api/user/referrals', {
            params: { telegramID }
        });
        return data;
    } catch (error) {
        console.error("Ошибка при получении рефералов:", error);
        return { referrals: [] };
    }
};

export const fetchTopUsers = async () => {
    try {
        const { data } = await $authHost.get('api/user/top');
        return data;
    } catch (error) {
        console.error("Ошибка при получении топ-игроков:", error);
        return [];
    }
};


