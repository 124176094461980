import React, { useContext, useEffect, useState } from "react";     
import './TaskPage.css';
import { NavLink, useParams } from "react-router-dom";
import { Context } from "../index";
import { updateUser, check } from "../http/userApi";
import { observer } from "mobx-react-lite";
import { fetchOneTasks, markTaskAsCompleted, checkTaskCompletion } from "../http/taskApi";

const TaskPage = observer(() => {
    const { user } = useContext(Context);
    const [userInfo, setUserInfo] = useState(null);
    const [task, setTask] = useState({});
    const [isCompleted, setIsCompleted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const taskData = await fetchOneTasks(id);
            const data = await check();
            setUserInfo(data);
            setTask(taskData);
            const completionStatus = await checkTaskCompletion(data.id, id);
            setIsCompleted(completionStatus.isCompleted);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCompleteTask = async () => {
        const userId = userInfo.id;
        await markTaskAsCompleted({ userId, taskId: id });
        setIsCompleted(true);
        await updateUser(userInfo.telegramID, userInfo.username, userInfo.role, Number(userInfo.balance) + Number(task.price), userInfo.level, userInfo.energy, userInfo.wallet);
    };

    const [selectedOption, setSelectedOption] = useState(null);
    
    const handleAnswer = async (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption === task.correctOption) {
            await handleCompleteTask();
            setErrorMessage(null);
        } else {
            setErrorMessage("Incorrect answer, try again!");
        }
    };

    if (!task.title) {
        return <div className="error">Task didn't founded</div>;
    }

    const imageUrl = task.image ? `${process.env.REACT_APP_API_URL}/images/${task.image}` : '';

    return (
        <div className="taskBlock">
            <div className="goBack_block">
                <NavLink to="/tasks" className="goBack_item">← Back</NavLink>
            </div>
            {!task.isQuiz && imageUrl && (
                <img className="taskBlock_icon" src={imageUrl} alt="Задание" />
            )}
            <div className="task_title_block">
                {task.title}
            </div>
            <div className="task_description_block">
                {task.description}
            </div>
            <div className="plus">
                💰 + {task.price}
            </div>
            {isCompleted ? (
                <div className="completion_message">You already completed this task before</div>
            ) : (
                task.isQuiz ? (
                <div className="quiz_options">
                    {task.options.map((option, index) => (
                        <button
                            key={index}
                            className={`quiz_option ${selectedOption === index ? (selectedOption === task.correctOption ? "correct" : "incorrect") : ""}`}
                            onClick={() => handleAnswer(index)}
                            disabled={isCompleted}
                        >
                            {option}
                        </button>
                    ))}
                </div>
                ) : (
                    <a className="task_button" onClick={handleCompleteTask} href={task.link} target="_blank" rel="noopener noreferrer">
                        Link
                    </a>
                )
            )}
            {errorMessage && <div className="error_message">{errorMessage}</div>}
        </div>
    );
});

export default TaskPage;
