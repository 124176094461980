import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useRef } from "react";
import { check } from "../http/userApi";
import { Context } from "../index";
import './Referal.css';

import { fetchReferrals } from "../http/userApi"; // Импортируем новый API метод

const Referal = observer(() => {
    const { user } = useContext(Context);
    const [userInfo, setUserInfo] = useState({});
    const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);
    const [referrals, setReferrals] = useState([]);
    const [isInfoVisible, setIsInfoVisible] = useState(false); // Состояние для видимости блока информации
    const infoBlockRef = useRef(null); // Ссылка на блок информации

    const earningsPerReferral = 5000; // Сумма заработка за одного приглашенного
    const totalEarnings = referrals.reduce((acc, referral) => acc + earningsPerReferral, 0); // Общий заработок

    // Функция для получения данных пользователя
    const fetchData = async () => {
        try {
            const data = await check();
            setUserInfo(data);
            setIsUserInfoLoaded(true);

            // Получаем список рефералов
            const referralsData = await fetchReferrals(data.telegramID);
            setReferrals(referralsData.referrals || []);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        function copyLink() {
            const linkInput = document.getElementById('link');
            linkInput.select();
            document.execCommand('copy');
            alert('Copied!');
        }

        // Добавляем обработчик клика на кнопку
        const button = document.getElementById('copyButton');
        button.addEventListener('click', copyLink);

        // Очистка обработчика при размонтировании компонента
        return () => {
            button.removeEventListener('click', copyLink);
        };
    }, [isUserInfoLoaded]);

    const link = isUserInfoLoaded ? `https://t.me/ForTestThings_bot/GameStart/?startapp=invite${userInfo.telegramID}` : '';

    const toggleInfoBlock = () => {
        setIsInfoVisible(!isInfoVisible);
    };

    const handleClickOutside = (event) => {
        if (infoBlockRef.current && !infoBlockRef.current.contains(event.target)) {
            setIsInfoVisible(false);
        }
    };

    useEffect(() => {
        if (isInfoVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isInfoVisible]);

    return (
        <div>
            <div className="task_list_block_title">Referral system</div>
            
            {/* Кнопка с восклицательным знаком */}
            <button 
                className="info-button" 
                onClick={toggleInfoBlock}
                style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '50%', width: '40px', height: '40px' }}
            >
                ℹ
            </button>

            {/* Блок с информацией */}
            {isInfoVisible && (
                <div ref={infoBlockRef} className="info-block">
                    <p>This is some important information about the referral system.</p>
                </div>
            )}

            <div className="referralInfoBlock">
                <div className="referralInfoBlockTitle">Earn per referral:</div>
                <div>{earningsPerReferral}</div>
            </div>

            <div className="referralInfoBlock">
                <div className="referralInfoBlockTitle">Total earned from referrals:</div>
                <div>{totalEarnings}</div>
            </div>

            <div className="referalLinkBlock">
                <input type="text" id="link" value={link} readOnly className="referalLink" />
            </div>
            
            <div className="copy-button-block">
                <button id="copyButton" className="copy-button">Copy 📎</button>
            </div>

            Your referrals:
            <ul className="referralList">
                {referrals.length > 0 ? referrals.map((referral, index) => (
                    <li className="referalCard" key={index}>
                        {index + 1}. {referral.username} 💰 {referral.balance}
                    </li>
                )) : <p>You don't have any referrals yet...</p>}
            </ul>
        </div>
    );
});

export default Referal;
