import React, { useState } from "react";
import CreateTask from "../components/modals/CreateTask/CreateTask";
import CreateQuizTask from "../components/modals/CreateQuizTask/CreateQuizTask";  // новый импорт
import './Admin.css';

const Admin = () => {
    const [toggle, setToggle] = useState(false);
    const [toggleQuiz, setToggleQuiz] = useState(false); // состояние для квиз-задания
    const [notification, setNotification] = useState(null);

    const handleTaskSubmission = (success, message) => {
        if (success) {
            setNotification({ type: "success", message: "Задание успешно добавлено!" });
        } else {
            setNotification({ type: "error", message: `Ошибка: ${message}` });
        }

        setTimeout(() => setNotification(null), 5000);
    };

    return (
        <div className="admin_page_block">
            <h2 className="page_title">Админ панель</h2>
            <div className="admin_panel_block">
                <div className="modal_button" onClick={() => setToggle(!toggle)}>
                    {toggle ? "Закрыть форму" : "Добавить задание"}
                </div>
                {toggle && <CreateTask onSubmit={handleTaskSubmission} />}
                <div className="modal_button" onClick={() => setToggleQuiz(!toggleQuiz)}>
                    {toggleQuiz ? "Закрыть форму" : "Добавить квиз задание"}
                </div>
                {toggleQuiz && <CreateQuizTask onSubmit={handleTaskSubmission} />}  {/* новая форма */}
            </div>
            {notification && (
                <div className={`notification ${notification.type}`}>
                    {notification.message}
                </div>
            )}
        </div>
    );
};

export default Admin;
