import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import "./Auth.css"; // Убедитесь, что стили находятся в этом файле
import { MAIN_ROUTE } from "../utils/consts";
import { registration, login, addBalance } from "../http/userApi";
import { useTelegram } from "../hooks/useTelegram";
import { Context } from "../index";
import { observer } from "mobx-react-lite";

const Auth = observer(() => {
    const { user } = useContext(Context);
    const navigate = useNavigate();
    const { userNameHook, tgIdHook } = useTelegram();

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true); // Состояние загрузки по умолчанию - true
    const [inviterId, setInviterId] = useState(0);

    useEffect(() => {
        // Проверка на наличие инвайт-кода
        if (window.Telegram.WebApp.initDataUnsafe.start_param) {
            if (window.Telegram.WebApp.initDataUnsafe.start_param.includes("invite")) {
                setInviterId(window.Telegram.WebApp.initDataUnsafe.start_param.replace("invite", ""));
            }
        }
        // Эмуляция задержки загрузки перед показом кнопки "Play"
        setTimeout(() => {
            setLoading(false); // Убираем состояние загрузки через 2 секунды
        }, 2000);
    }, []);

    const click = async () => {
        setLoading(true); // Начинаем загрузку при клике
        try {
            await registration(tgIdHook, userNameHook, inviterId.toString());
            await login(tgIdHook, userNameHook);
            user.setUser(user);
            user.setIsAuth(true);
            navigate(MAIN_ROUTE);
            await addBalance(inviterId.toString(), 5000);
        } catch (e) {
            console.log(e);
            if (e.response.data.message === "Пользователь с таким telegramId уже существует") {
                console.log("Так ты уже с нами :). Ну заходи");
                try {
                    await login(tgIdHook, userNameHook);
                    user.setUser(user);
                    user.setIsAuth(true);
                    navigate(MAIN_ROUTE);
                } catch (e) {
                    console.log(e);
                }
            }
        } finally {
            setLoading(false); // Завершаем загрузку после операции
        }
    }

    return (
        <div className="main_block">
            {loading ? (
                <div className="loading_container">
                    <div className="loading_bar"></div> {/* Загрузочный бар */}
                </div>
            ) : (
                <div className="letsPlay" onClick={click}>Play</div> // Кнопка "Играть"
            )}
        </div>
    );
});

export default Auth;
