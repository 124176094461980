import Admin from "./pages/Admin";
import AuthPage from "./pages/Auth";
import Game from "./pages/Game";
import TaskList from "./pages/TaskList";
import TaskPage from "./pages/TaskPage";
import Main from "./pages/main"
import Referal from "./pages/Referal";
import pcPage from "./pages/Pc";
import LeaderBoard from "./pages/LeaderBoard";
import WalletPage from "./pages/Wallet";
import { ADMIN_ROUTE, GAME_ROUTE, REGISTRATION_ROUTE, TASK_ROUTE, TASK_LIST_ROUTE, MAIN_ROUTE, REFERAL_ROUTE, PC_JOIN, LEADER_BOARD, WALLET_ROUTE } from "./utils/consts";
import { Component } from "react";

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: GAME_ROUTE,
        Component: Game
    },
    {
        path: TASK_LIST_ROUTE,
        Component: TaskList
    },
    {
        path: TASK_ROUTE + '/:id',
        Component: TaskPage
    },
    {
        path: MAIN_ROUTE,
        Component: Main
    },
    {
        path: REFERAL_ROUTE,
        Component: Referal
    },
    {
        path: LEADER_BOARD,
        Component: LeaderBoard
    },
    {
        path: WALLET_ROUTE,
        Component: WalletPage
    }
]

export const publicRoutes = [
{
        path: REGISTRATION_ROUTE,
        Component: AuthPage
    },
    {
        path: PC_JOIN,
        Component: pcPage
    }
    
]