import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useRef  } from "react";
import { Context } from "../index";
import './LeaderBoard.css'; // Создайте CSS файл для оформления
import "./Referal.css";

import { fetchTopUsers } from "../http/userApi"; // Добавляем новый API метод

const LeaderBoard = observer(() => {
    const { user } = useContext(Context);
    const [topPlayers, setTopPlayers] = useState([]);

    const fetchData = async () => {
        try {
            const data = await fetchTopUsers(); // Получаем список топ-игроков
            setTopPlayers(data);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    
    const [isInfoVisible, setIsInfoVisible] = useState(false); // Состояние для видимости блока информации
    const infoBlockRef = useRef(null); // Ссылка на блок информации

    const toggleInfoBlock = () => {
        setIsInfoVisible(!isInfoVisible);
    };

    const handleClickOutside = (event) => {
        if (infoBlockRef.current && !infoBlockRef.current.contains(event.target)) {
            setIsInfoVisible(false);
        }
    };

    useEffect(() => {
        if (isInfoVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isInfoVisible]);

    return (
        <div>
            <div className="task_list_block_title">Leader Board</div>

                        {/* Кнопка с восклицательным знаком */}
                        <button 
                className="info-button" 
                onClick={toggleInfoBlock}
                style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '50%', width: '40px', height: '40px' }}
            >
                ℹ
            </button>

            {/* Блок с информацией */}
            {isInfoVisible && (
                <div ref={infoBlockRef} className="info-block">
                    <p>This is some important information about the referral system.</p>
                </div>
            )}

            <ul className="referralList">
                {topPlayers.length > 0 ? topPlayers.map((player, index) => (
                    <li className="referalCard" key={index}>
                        {index + 1}. {player.username} 💰 {player.balance}
                    </li>
                )) : <p>No players yet...</p>}
            </ul>
        </div>
    );
});

export default LeaderBoard;
