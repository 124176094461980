import "./NavBar.css";
import playIcon from "../../img/main/playicon.png";
import taskIcon from "../../img/main/taskIcon.png";
import adminIcon from "../../img/main/adminIcon.png";
import inviteIcon from "../../img/main/inviteIcon.png";
import leaderboardIcon from "../../img/main/leaderboardIcon.png";
import { MAIN_ROUTE, TASK_LIST_ROUTE, ADMIN_ROUTE, REFERAL_ROUTE, WALLET_ROUTE } from "../../utils/consts";
import { check } from "../../http/userApi";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";

const NavBar = observer(() => {
  const [userRole, setUserRoleInfo] = useState({});

  const fetchData = async () => {
    try {
      const data = await check();
      setUserRoleInfo(data.role);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="navBar_block">
      <div className="navBar_links">
        <div className="navBar_link_block">
          <NavLink to={MAIN_ROUTE} className="navBar_link_item" activeClassName="active">
            <img src={playIcon} alt="playIcon" className="navBar_link_icon" />
            Game
          </NavLink>
        </div>
        <div className="navBar_link_block">
          <NavLink to={TASK_LIST_ROUTE} className="navBar_link_item" activeClassName="active">
            <img src={taskIcon} alt="taskIcon" className="navBar_link_icon" />
            Tasks
          </NavLink>
        </div>
        {userRole === "ADMIN" && (
          <div className="navBar_link_block">
            <NavLink to={ADMIN_ROUTE} className="navBar_link_item" activeClassName="active">
              <img src={adminIcon} alt="adminIcon" className="navBar_link_icon" />
              Admin
            </NavLink>
          </div>
        )}
        <div className="navBar_link_block">
          <NavLink to={REFERAL_ROUTE} className="navBar_link_item" activeClassName="active">
            <img src={inviteIcon} alt="inviteIcon" className="navBar_link_icon" />
            Invite
          </NavLink>
        </div>
        <div className="navBar_link_block">
          <NavLink to="/leaderboard" className="navBar_link_item" activeClassName="active">
            <img src={leaderboardIcon} alt="leaderboardIcon" className="navBar_link_icon" />
            Leaders
          </NavLink>
        </div>
        <div className="navBar_link_block">
          <NavLink to={WALLET_ROUTE} className="navBar_link_item" activeClassName="active">
            <img src={adminIcon} alt="walletIcon" className="navBar_link_icon" />
            Wallet
          </NavLink>
        </div>
      </div>
    </div>
  );
});

export default NavBar;
