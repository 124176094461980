import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { check, updateUser } from "../http/userApi";
import { GAME_ROUTE } from "../utils/consts";
import "./main.css";
import { Context } from "../index";
import avatar from "../img/main/avatar.png";
import { parseISO } from 'date-fns';

const MAX_ATTEMPTS = 3; // Максимальное количество попыток

const Main = observer(() => {
  const [attempts, setAttempts] = useState(0);
  const { user } = useContext(Context);
  const [userInfo, setUserInfo] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

  const fetchData = async () => {
    try {
      const data = await check();
      setUserInfo(data);
      setAttempts(data.attempts); // Используйте attempts вместо energy
      setIsUserInfoLoaded(true);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  useEffect(() => {
  
    if (isUserInfoLoaded && attempts < MAX_ATTEMPTS) {
      if (!userInfo.lastAttemptUpdate) {
        console.error("Дата последнего обновления попыток отсутствует:", userInfo.lastAttemptUpdate);
        setTimeRemaining(null);
        return;
      }
  
  
      if (isNaN(parseISO(userInfo.lastAttemptUpdate))) {
        console.error("Неверный формат даты:", userInfo.lastAttemptUpdate);
        setTimeRemaining(null);
        return;
      }
  
      const updateInterval = setInterval(() => {
        const now = new Date();
        const nextUpdate = parseISO(userInfo.lastAttemptUpdate).getTime() + 8 * 60 * 60 * 1000; // 8 часов
        const remainingTime = Math.max(nextUpdate - now, 0);
  
  
        setTimeRemaining(remainingTime);
  
        if (remainingTime === 0) {
          fetchData(); // Обновляем данные
          clearInterval(updateInterval);
        }
      }, 1000);
  
      return () => {
        clearInterval(updateInterval);
      };
    } else {
      setTimeRemaining(null);
    }
  }, [isUserInfoLoaded, attempts, userInfo.lastAttemptUpdate]);
  

  const getAttemptsClass = () => {
    if (attempts === 3) return "attempts-high";
    if (attempts === 2) return "attempts-medium";
    if (attempts === 1) return "attempts-low";
    return "attempts-empty"; // Если попыток нет
  };

  const buttonClass = attempts < 1 ? "gameButton inactive" : "gameButton";

  const handlePlayClick = async (e) => {
    if (attempts < 1) {
      e.preventDefault();
      return;
    }
    await updateUser(userInfo.telegramID, userInfo.username, userInfo.role, userInfo.balance, userInfo.level, attempts);
  };

  const formatTime = (ms) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const [isRulesVisible, setIsRulesVisible] = useState(false);

  const toggleRules = () => {
    setIsRulesVisible(!isRulesVisible);
  };

  return (
    <div className="gameBlock">
      <div className="gameScreen">
        <div className="profileCard">
          <img src={avatar} alt="userAvatar" className="userAvatar" />
          <div className="username atkinson-hyperlegible-bold">
            Hello,
            <br />
            {userInfo.username}
          </div>
        </div>

        <div className="balanceCard">
          <div className="balance atkinson-hyperlegible-bold">
            SCORE <br/> {userInfo.balance}.00
          </div>
        </div>

        {timeRemaining !== null && (
          <div className="timer">
            <span>We will give some energy after:</span>
            <div className="timer-display">
              {timeRemaining === 0 ? "Let's count the time 🤔" : formatTime(timeRemaining)}
            </div>
          </div>
        )}

        <div className="energy-bar-container">
          <div className="energy-bar">
            {[...Array(MAX_ATTEMPTS)].map((_, index) => (
              <div
                key={index}
                className={`energy-fill ${index < attempts ? getAttemptsClass() : "attempts-empty"}`}
              ></div>
            ))}
          </div>
        </div>

        <NavLink
          to={GAME_ROUTE}
          className={buttonClass}
          onClick={handlePlayClick}>
          PLAY
        </NavLink>

        <button className="how-to-play-button" onClick={toggleRules}>
          How to play?
        </button>

        {isRulesVisible && (
          <div className="rules-block">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default Main;
