import React, { useContext, useEffect, useState, useRef } from "react";
import TaskListComp from "../components/TaskListComp/TaskListComp";
import './TaskList.css';
import { fetchTasks } from "../http/taskApi";
import { observer } from "mobx-react-lite";
import { Context } from "../index";

const TaskList = observer(() => {
    const { task } = useContext(Context);
    const [totalReward, setTotalReward] = useState(0); // состояние для хранения суммы наград

    useEffect(() => {
        fetchTasks().then(data => {
            task.setTasks(data.rows);
            calculateTotalReward(data.rows); // подсчет общей суммы наград
        });
    }, []);

    // Функция для подсчета суммы наград активных заданий
    const calculateTotalReward = (tasks) => {
        const total = tasks.reduce((sum, current) => {
            return current.isActive ? sum + Number(current.price) : sum; // добавляем награду к сумме
        }, 0);
        setTotalReward(total); // обновляем состояние
    };

    const [isInfoVisible, setIsInfoVisible] = useState(false); // Состояние для видимости блока информации
    const infoBlockRef = useRef(null); // Ссылка на блок информации

    const toggleInfoBlock = () => {
        setIsInfoVisible(!isInfoVisible);
    };

    const handleClickOutside = (event) => {
        if (infoBlockRef.current && !infoBlockRef.current.contains(event.target)) {
            setIsInfoVisible(false);
        }
    };

    useEffect(() => {
        if (isInfoVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isInfoVisible]);

    return (
        <div className="task_list_block">
                        {/* Кнопка с восклицательным знаком */}
                        <button 
                className="info-button" 
                onClick={toggleInfoBlock}
                style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '50%', width: '40px', height: '40px' }}
            >
                ℹ
            </button>

            {/* Блок с информацией */}
            {isInfoVisible && (
                <div ref={infoBlockRef} className="info-block">
                    <p>This is some important information about the referral system.</p>
                </div>
            )}

            <div className="task_list_block_title">Tasks</div>
            <div className="total_reward">
                <div className="reward_amount">
                    {totalReward} 💰
                </div>
                <div className="reward_label">Total Reward</div>
            </div>
            <TaskListComp />
        </div>
    );
});

export default TaskList;
