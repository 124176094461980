import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { createTask } from "../../../http/taskApi";
import "./CreateTask.css";

const CreateTask = observer(() => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [link, setLink] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState('');

  const selectFile = (e) => {
    setImage(e.target.files[0]);
    setError('');
  };

  const addTask = () => {
    if (!image) {
      setError("Пожалуйста, загрузите изображение.");
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('shortDescription', shortDescription);
    formData.append('link', link);
    formData.append('price', price);
    formData.append('image', image);
    formData.append('isActive', isActive);

    try {
      createTask(formData).then(() => {
        document.getElementById("good").style = showUp;
      });
    } catch (e) {
      document.getElementById("bad").style = showUp;
      console.error(e);
    }
  };

  const showUp = {
    display: "block",
    transition: "0.2s",
  };

  const hide = {
    display: "none",
    transition: "0.2s",
  };

  return (
    <div>
      <div className="notification" id="good" style={hide}>
        <button className="delete"></button>
        Задача успешно добавлена
      </div>

      <div className="notification is-danger" id="bad" style={hide}>
        <button className="delete"></button>
        Что-то пошло не так
      </div>

      {error && (
        <div className="notification is-danger">
          <button className="delete" onClick={() => setError('')}></button>
          {error}
        </div>
      )}
      
      <form className="add_task_form">
        <div className="add_task_form_field_block">
          <p className="field_title">Введите название задания:</p>
          <input
            className="input_field"
            type="text"
            value={title}
            required
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Введите описание задания:</p>
          <input
            className="input_field"
            type="text"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Введите краткое описание:</p>
          <input
            className="input_field"
            type="text"
            value={shortDescription}
            required
            onChange={(e) => setShortDescription(e.target.value)}
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Введите ссылку:</p>
          <input
            className="input_field"
            type="url"
            value={link}
            required
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Введите сумму коинов:</p>
          <input
            className="input_field"
            type="number"
            value={price}
            placeholder="0"
            required
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Загрузите иконку для задания:</p>
          <input
            type="file"
            onChange={selectFile}
            required
          />
        </div>
        <div className="add_task_form_field_block">
          <p className="field_title">Задание будет сразу активно:</p>
          <input
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </div>
        <div className="form_button_block">
          <button
            type="button"
            className="submit_form_button"
            onClick={addTask}
          >
            Добавить задание
          </button>
        </div>
      </form>
    </div>
  );
});

export default CreateTask;
