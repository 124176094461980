import React, { useState } from "react";
import { createQuizTask } from "../../../http/taskApi"; // API для создания квиз-задания
import "./CreateQuizTask.css"; // Подключаем стили

const CreateQuizTask = ({ onSubmit }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [options, setOptions] = useState(["", "", "", ""]); // 4 варианта ответа
    const [correctOption, setCorrectOption] = useState(0); // индекс правильного ответа
    const [price, setPrice] = useState(0);

    const handleSubmit = async () => {
        try {
            const task = { title, description, options, correctOption, price };
            await createQuizTask(task); // вызываем API для создания квиз-задания
            onSubmit(true, "Квиз задание успешно добавлено!");
        } catch (error) {
            onSubmit(false, "Ошибка при добавлении задания");
        }
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    return (
        <div className="create_task_modal">
            <h3>Создать квиз задание</h3>
            <div className="add_task_form_field_block">
                <p className="field_title">Введите название задания:</p>
                <input
                    className="input_field"
                    type="text"
                    placeholder="Название задания"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
            </div>
            <div className="add_task_form_field_block">
                <p className="field_title">Введите описание задания:</p>
                <textarea
                    className="input_field"
                    placeholder="Описание задания"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
            </div>
            <h4>Варианты ответа</h4>
            {options.map((option, index) => (
                <div key={index} className="add_task_form_field_block">
                    <input
                        className="input_field"
                        type="text"
                        placeholder={`Ответ ${index + 1}`}
                        value={option}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        required
                    />
                </div>
            ))}
            <div className="add_task_form_field_block">
                <p className="field_title">Выберите правильный ответ:</p>
                <select className="input_field" value={correctOption} onChange={(e) => setCorrectOption(Number(e.target.value))}>
                    {options.map((_, index) => (
                        <option key={index} value={index}>
                            Правильный ответ {index + 1}
                        </option>
                    ))}
                </select>
            </div>
            <div className="add_task_form_field_block">
                <p className="field_title">Введите сумму коинов:</p>
                <input
                    className="input_field"
                    type="number"
                    id="price"
                    value={price}
                    placeholder="0"
                    required
                    onChange={(e) => setPrice(Number(e.target.value))}
                />
            </div>
            <div className="form-add_task_form_button_block">
                <div className="form_button_block">
                    <button
                        className="submit_form_button"
                        onClick={handleSubmit}
                        type="button"
                    >
                        Добавить задание
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateQuizTask;
