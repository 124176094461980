import { useEffect, useContext, useState } from 'react';
import { useTelegram } from './hooks/useTelegram';
import NavBar from "./components/NavBar/NavBar";
import './App.css';
import { BrowserRouter } from "react-router-dom";
import AppRouter from './components/AppRouter';
import { Context } from "./index";
import { observer } from 'mobx-react-lite';
import { check } from "./http/userApi";
import qr from "./img/main/qr-code.svg";
import logo from "./img/main/Logo.svg"

const App = observer(() => {
  const { tg } = useTelegram();
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    tg.ready();
    localStorage.clear();

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile|android|iphone|ipad|ipod/.test(userAgent);
    setIsMobile(isMobileDevice);

    if (isMobileDevice) {
      if (localStorage.length > 0) {
        for (let key, i = 0; i < localStorage.length; i++) {
          key = localStorage.key(i);
          if (key === "token") {
            check().then(data => {
              if (typeof(data) !== "undefined") {
                user.setUser(true);
                user.setIsAuth(true);
              }
            }).finally(() => setLoading(false));
            break;
          } else {
            setLoading(false);
            break;
          }
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [tg]);

  if (loading) {
    return <button className='loadingSpinner'></button>;
  }

  if (!isMobile) {
    return (
      <div className="non-mobile-warning">
        <img src={logo} alt="logo" className="logo" />
        <img src={qr} alt="QR Code" className="qr-code" />
        <h1>Play this awesome game on your phone!</h1>
      </div>
    );
  }

  return (
    <BrowserRouter className="App">
      <div className='container'>
        <AppRouter/>
        {user.isAuth ? <NavBar /> : <></>}
      </div>
    </BrowserRouter>
  );
});

export default App;
