import React from "react";
import GameWindow from "../components/modals/Game/GameWindow";

const Game = () => {
    return (
        <div>
        <GameWindow/>
        </div>
    );
};

export default Game;